import React from 'react'

const TrialBtn = ({className}) => {
    const handleOnClick = async () => {
        window.open("https://books.fexo.io/auth/create-account", "_blank");
    };
    return (
      <button
        className={`${className} bg-orange-500 rounded-lg shadow-xl text-white text-2xl`}
        onClick={() => {
          handleOnClick();
        }}
      >
        Start Free Trial
      </button>
    );
}

export default TrialBtn