import React from "react";
import { CheckCircleOutline } from "@mui/icons-material";
//import { YOUTUBE_HOMEPAGE } from "../config";
import BookCallBtn from "../components/InvoiceProcessing/BookCallBtn";

const features = [
  {
    title: "KYC Management",
    description:
      "Simplify KYC compliance with our automated document extraction and validation system. Ensure compliance while reducing processing times",
  },
];

function KYCManagement() {
  return (
    <div className="text-justify">
      <div className="text-stone-900 text-4xl font-bold text-center ">
        KYC Management
      </div>
      <div className="middle-container">
        {features.map((feature, index) => (
          <div key={index} className="main-container">
            <div className="text-stone-900 mt-7 text-lg font-normal">
              {feature.description}
            </div>

            <div className="flex col-3 gap-8 mt-10">
              <div className="w-full text-lg font-normal flex flex-col">
                <div className="flex justify-center items-center">
                  Proof Of Identity
                </div>
                <div className="flex bg-[#F1E0F9] flex-col text-lg font-normal h-[250px] rounded-lg w-fit">
                  <ul className="ml-8 mt-5">
                    <li>Passport</li>
                    <li>Aadhar Card</li>
                    <li>Voter ID Card</li>
                    <li>Pan Card</li>
                    <li>Driving Licence</li>
                    <li>Any other photo ID issued by Government Authorities</li>
                  </ul>
                </div>
              </div>

              <div className="w-full text-lg font-normal flex flex-col">
                <div className="flex justify-center items-center">
                  Proof Of Address
                </div>
                <div className="flex bg-[#F1E0F9] flex-col text-lg font-normal h-[250px] rounded-lg w-fit">
                  <ul className="ml-8 mt-5 mr-8">
                    <li>Utility Bills</li>
                    <li>Bank Statement or Passbook</li>
                    <li>Voter ID Card</li>
                    <li>Property Tax Receipt</li>
                    <li>Insurance Policy Statements</li>
                  </ul>
                </div>
              </div>

              <div className="w-full text-lg font-normal flex flex-col">
                <div className="flex justify-center items-center">
                  Proof Of Income
                </div>
                <div className="flex bg-[#F1E0F9] flex-col text-lg font-normal h-[250px] rounded-lg w-fit">
                  <ul className="ml-8 mt-5 mr-8">
                    <li>Salary Slips</li>
                    <li>Employers Certificate of Income </li>
                    <li>Offer Letter</li>
                    <li>Other certificates/documents</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className=" font-medium mt-5 text-xl">Key Features</div> */}

            {/* {feature.keyFeatures.map((item, idx) => (
             <div className="flex items-center mt-7" key={idx}>
              <CheckCircleOutline className="text-orange-400 mr-3" />
              <span className="text-lg">{item}</span>
            </div>
          ))} */}

            {/* <div className="w-full rounded-lg bg-white shadow-xl p-5 mt-5 md:mt-10">
            <iframe
              frameBorder="0"
              allowFullScreen="1"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              title={`Demo Video`}
              src={`https://www.youtube.com/embed/${YOUTUBE_HOMEPAGE}?autoplay=0&amp;cc_lang_pref=en&amp;cc_load_policy=1&amp;controls=0&amp;rel=0&amp;hl=en&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fsupport.google.com&amp;widgetid=1`}
              className="w-full h-[250px] rounded-lg lg:h-[450px]"
            />
          </div> */}
            <div className="flex justify-center mt-10 w-full">
              <BookCallBtn className="w-64 h-16 z-50" text="Book a demo" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default KYCManagement;
