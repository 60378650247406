// import { lazy } from "react";
import { Navigate } from "react-router-dom";

// const InvoiceProcessingHomepage = lazy(() => import("../pages/Homepage"));
// const PlaygroundPage = lazy(() => import("../pages/PlaygroundPage"));
// const ExtractionPage = lazy(() => import("../pages/ExtractionPage"));
// const ResultPage = lazy(() => import("../pages/kleeto/ResultPage"));
// const UploadPage = lazy(() => import("../pages/kleeto/UploadPage"));
// const ChineseUploadPage = lazy(() => import("../pages/chinese/UploadPage"));
// const ChineseExtractionPage = lazy(() =>
//   import("../pages/chinese/ExtractionPage")
// );
// const StaticPage = lazy(() => import("../pages/StaticPage"));

import InvoiceProcessingHomepage from "../pages/Homepage";
import BookKeeping from "../pages/BookKeeping";
import PlaygroundPage from "../pages/PlaygroundPage";
import ExtractionPage from "../pages/ExtractionPage";
import ResultPage from "../pages/kleeto/ResultPage";
import UploadPage from "../pages/kleeto/UploadPage";
import ChineseUploadPage from "../pages/chinese/UploadPage";
import ChineseExtractionPage from "../pages/chinese/ExtractionPage";
import StaticPage from "../pages/StaticPage";
import ExplorePage from "../pages/ExplorePage";
import ArticlePage from "../components/HomePageContent/ArticlePage";

import DocQuery from "../pages/DocQuery";
import ZohoBooksIntegration from "../components/ClientPages/ZohoBooksIntegration";
import ZohoBooksIntegrationGuide from "../components/ClientPages/ZohoBooksIntegrationGuide";
// import PaymentReceiptUploadPage from "../pages/PaymentReceipt/UploadPage";
// import PaymentReceiptExtractionPage from "../pages/PaymentReceipt/ResultPage";

export const router = [
  {
    path: "/",
    element: <InvoiceProcessingHomepage />,
  },
  {
    path: "/bookkeepingautomation",
    element: <BookKeeping />,
  },
  {
    path: "/playground",
    element: <PlaygroundPage />,
  },
  {
    path: "/playground/:jobId",
    element: <ExtractionPage />,
  },
  {
    path: "/kleeto",
    element: <UploadPage />,
  },
  {
    path: "/kleeto/:jobId",
    element: <ResultPage />,
  },
  {
    path: "/chinese",
    element: <ChineseUploadPage />,
  },
  {
    path: "/chinese/:jobId",
    element: <ChineseExtractionPage />,
  },
  // {
  //   path: "/payment-receipt",
  //   element: <PaymentReceiptUploadPage />,
  // },
  // {
  //   path: "/payment-receipt/:jobId",
  //   element: <PaymentReceiptExtractionPage />,
  // },
  {
    path: "/privacy-policy",
    element: <StaticPage />,
  },
  {
    path: "/terms-of-service",
    element: <StaticPage />,
  },
  {
    path: "/subprocessors",
    element: <StaticPage />,
  },
  {
    path: "/gdpr",
    element: <StaticPage />,
  },
  {
    path: "/security-policy",
    element: <StaticPage />,
  },
  {
    path: "/cookie-policy",
    element: <StaticPage />,
  },
  {
    path: "/service-levels",
    element: <StaticPage />,
  },
  {
    path: "/creaditassessment",
    element: <ExplorePage />,
  },
  {
    path: "/kycmanagement",
    element: <ExplorePage />,
  },
  {
    path: "/tradefinance",
    element: <ExplorePage />,
  },
  {
    path: "/regulatorycomplaince",
    element: <ExplorePage />,
  },
  {
    path: "/financialstatement",
    element: <ExplorePage />,
  },
  {
    path: "/customersupport",
    element: <ExplorePage />,
  },
  {
    path: "/docquery",
    element: <DocQuery />,
  },
  {
    path: "/article/:id",
    element: <ArticlePage />,
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },{
    path: "/zoho-books",
    element : <ZohoBooksIntegration/>
  }
  ,{
    path: "/zoho-books-integration-guide",
    element : <ZohoBooksIntegrationGuide/>
  }
];
