import React from "react";
import { Link } from "react-router-dom";
function Terms() {
  const handleContactClick = () => {
    const email = "hello@fexo.io";
    const subject = "";
    const body = "";
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className="text-justify">
      <p className="text-stone-900 text-base font-normal ">
        Effective From: 10th January 2024
      </p>

      <h2 className="static-subheading">1. INTRODUCTION</h2>
      <p className="text-stone-900 text-base font-normal ">
        The Terms of Services ("Agreement" “Terms”) governs the use of the
        software platform by name Fexo (“Service”) provided by OpenTurf
        Technologies Private Limited (“OpenTurf” “Company” “we” “us” “our”) to
        you (“Customer” “User” “your” “you”).
        <br />
        <br />
        By accessing or using the Service, you agree to these Terms.
        <br />
      </p>

      <h2 className="static-subheading">2. ACCEPTANCE OF TERMS OF SERVICES</h2>
      <p className="text-stone-900 text-base font-normal ">
        By creating and registering an account on Services’ website, located at{" "}
        <Link
          className="underline text-purple-800"
          target="_blank"
          rel="noopener noreferrer"
          to="https://www.fexo.io"
        >
          www.fexo.io
        </Link>{" "}
        ("Website") or any subdomains, like{" "}
        <Link
          className="underline text-purple-800"
          target="_blank"
          rel="noopener noreferrer"
          to="https://books.fexo.io"
        >
          books.fexo.io
        </Link>{" "}
        ("Website"), and mutually executing one or more order forms referencing
        these terms (each, an "Order"), or by accessing or using the Service in
        any manner, you hereby agree to be bound by these terms. This Agreement,
        together with all order forms, constitutes the entire understanding
        between the parties, to the exclusion of all other terms. You affirm
        that (A) you are at least 18 years of age; (B) you have the authority to
        enter into this agreement; and (C) if entering into this agreement on
        behalf of an organization or entity, you have the authority to bind such
        entity and its affiliates to these terms. In such cases, the terms
        "Customer," "You," and "Your" shall refer to the entity and its
        affiliates. If you lack such authority or disagree with the terms of
        this agreement, you are not permitted to use or access the services in
        any manner. Acceptance, if the terms are considered an offer, is
        expressly limited to such terms.
        <br />
      </p>
      <h2 className="static-subheading">3. SERVICE & LICENSE</h2>
      <p className="text-stone-900 text-base font-normal ">
        3.1 Services. OpenTurf provides Services to its Customers, located at
        its Website, fully described under an applicable "Order" (as defined
        under Section 3.4). OpenTurf will use commercially reasonable efforts to
        make the Services on its Website available to the Customer, subject to
        receipt of the applicable Fees (as detailed under an Order).
        <br />
        <br />
        3.2 License to OpenTurf Services. OpenTurf grants the Customer a
        limited, non-exclusive, non-sub-licensable, non-transferable,
        non-assignable right to access and use the Website for the Term (as
        defined under Section 8.1), solely for internal business purposes and in
        compliance with any additional limitations specified under an applicable
        Order.
        <br />
        <br />
        3.3 On-Premise Deployment. If Customer accesses the Website through an
        on-premises deployment, the right to access such service is limited to
        the time period mentioned in the applicable Order. Use of the Services
        through such deployment is limited, non-exclusive, non-sub-licensable,
        non-transferable, and non-assignable. Upon the lapse of the applicable
        Service duration, Customer must destroy and permanently delete all
        OpenTurf data from their premises. OpenTurf retains the right to inspect
        and audit customer's servers for OpenTurf usage data and any violation
        of the limited license for one year after the lapse of the applicable
        Service duration. Unauthorized consumption of additional licenses
        without prior payment constitutes a material breach, remediable only by
        pro-rata payment to OpenTurf.
        <br />
        <br />
        3.4 Order. For the purposes of this Agreement, an "Order" is defined as
        an order form or other similar document (including but not limited to an
        online order form) that details the Services being provided hereunder
        and the applicable Fees to be paid by the Customer to OpenTurf. The
        Order may also provide details regarding the service level terms (if
        any). In the event of a conflict between the Order and the Terms of
        Service, the Order will prevail.
        <br />
        <br />
        3.5 Trials. If Customer accesses the Service on a trial basis, use is
        limited to specified service levels, and the trial is provided "as-is"
        without indemnification, support, or warranties.
        <br />
        <br />
        3.6 No-Fee Access. If Customer uses the Service on a no-fee basis
        (Limited Use), use is subject to specified service levels, and the
        Limited Use is provided "as-is" without indemnification, support, or
        warranties.
        <br />
        <br />
        3.7 Support Services. OpenTurf may provide Support Services subject to
        additional Fees detailed in an applicable Order.
        <br />
        <br />
        3.8 Service Suspension. OpenTurf may suspend Customer's access to the
        Services for security reasons, insolvency, breach of the Agreement, or
        failure to pay Fees.
        <br />
      </p>

      <h2 className="static-subheading">4. RESTRICTIONS & RESERVATIONS</h2>
      <p className="text-stone-900 text-base font-normal ">
        4.1 Restrictions. Customers agree to use the Services in compliance with
        applicable laws, including data protection and privacy laws. Customers
        must not alter proprietary notices, reverse engineer, or allow
        third-party access.
        <br />
        <br />
        4.2 Reservation. The Services are provided under license, and nothing
        grants ownership rights. OpenTurf and its licensors retain all rights,
        title, and interest in the Services, except as expressly granted in this
        Agreement.
        <br />
      </p>

      <h2 className="static-subheading">5. PROPRIETARY RIGHTS</h2>
      <p className="text-stone-900 text-base font-normal ">
        5.1 Ownership. OpenTurf retains all rights, title, and interest, whether
        pre-existing or otherwise, in and to the Services, as well as any
        software, products, works, or other intellectual property created, used,
        provided, or made available by OpenTurf under or in connection with the
        Services pursuant to this Agreement. Additionally, OpenTurf is the owner
        of the "Service Software" embedded in the Services. For the purpose of
        this Agreement, "Service Software" encompasses any OpenTurf or
        third-party software code, computer program, documentation, new
        versions, updates, enhancements, upgrades, revisions, improvements, and
        modifications of the foregoing that OpenTurf has embedded into the
        Services. This proprietary Service Software is owned by OpenTurf and is
        provided to the Customer under this Agreement. The Customer acknowledges
        that OpenTurf holds all rights related to the Service Software. Subject
        to the terms and conditions of this Agreement, including the receipt of
        all applicable fees, OpenTurf grants the Customer a limited,
        non-exclusive, non-transferable, non-assignable, and non-sub-licensable
        license to run such Service Software solely as necessary to make use of
        the Services.
        <br />
        <br />
        5.2 Restrictions. The Customer agrees not to: (a) exceed the scope of
        the licenses granted in Section 5.1; (b) make copies of the Service
        Software; (c) distribute, sublicense, assign, delegate, rent, lease,
        sell, time-share, or otherwise transfer the benefits of, use under, or
        rights to the license granted in Section 3.2 and Section 5.1; (d)
        reverse engineer, decompile, disassemble, or otherwise attempt to learn
        the source code, structure, or algorithms underlying the software,
        except to the extent required to be permitted under applicable law; (e)
        modify, translate, or create derivative works of the Service Software;
        (f) remove any copyright, trademark, patent, or other proprietary notice
        that appears on the Service Software or copies thereof; or (g) combine
        or distribute any of the Service Software with any third-party software
        that is licensed under terms requiring the provision of software (or any
        associated intellectual property rights) in source code form (e.g., as
        "open source"), licensed to others to allow the creation or distribution
        of derivative works, or distributed without charge.
        <br />
        <br />
        5.3 Feedback. Customer may provide suggestions, comments, or other
        feedback to OpenTurf regarding the Services ("Feedback"). Customer
        grants OpenTurf a nonexclusive, worldwide, perpetual, irrevocable,
        transferable, sub-licensable, royalty-free, fully paid-up license to use
        and exploit the Feedback for any purpose.
        <br />
        <br />
        5.4 Disclaimer. Nothing in this Agreement will impair OpenTurf's right
        to develop, acquire, license, market, promote, or distribute products,
        software, or technologies that perform the same or similar functions as,
        or otherwise compete with any products, software, or technologies that
        the Customer may develop, produce, market, or distribute.
        <br />
      </p>

      <h2 className="static-subheading">6. DATA PRIVACY</h2>
      <p className="text-stone-900 text-base font-normal ">
        6.1 OpenTurf Privacy Policy. OpenTurf's current Privacy Policy is
        accessible at the{" "}
        <Link
          className="underline text-purple-800"
          target="_blank"
          rel="noopener noreferrer"
          to="/privacy-policy"
        >
          "Privacy Policy"
        </Link>{" "}
        (the "Privacy Policy"), and it is incorporated herein by reference.
        Please review the Privacy Policy to understand OpenTurf's information
        collection, usage, and disclosure practices with respect to information
        collected through the Services.
        <br />
        <br />
        6.2 Customer Data. Customer Data is and shall remain the property of the
        Customer. For the purpose of this Agreement, "Customer Data" refers to
        any data, information, or other material provided, uploaded, or
        submitted by the Customer to the Service during the use of the Services.
        Customer, not OpenTurf, is solely responsible for the accuracy, quality,
        integrity, legality, reliability, appropriateness, and intellectual
        property ownership or right to use all Customer Data. Customer grants
        OpenTurf a non-exclusive, non-transferable, non-sub-licensable,
        worldwide, royalty-free license to use, collect, transfer, and process
        the Customer Data solely for providing the Services to the Customer
        under the terms of the applicable Order and this Agreement.
        <br />
        <br />
        6.3 Derived Data. Customer understands and acknowledges that OpenTurf
        may generate "Derived Data" from the Customer Data. "Derived Data"
        refers to data submitted to, collected by, or generated by OpenTurf from
        the Customer Data in connection with the Customer's use of the Services.
        Customer agrees that OpenTurf may use Derived Data for its internal
        business purposes, including improving, testing, operating, promoting,
        and marketing OpenTurf's products and services.
        <br />
        <br />
        6.4 Customer Responsibility. Customer is solely responsible for Customer
        Data, including but not limited to: (a) compliance with all applicable
        laws and this Agreement; (b) any claims related to Customer Data; (c)
        any claims that Customer Data infringes, misappropriates, or otherwise
        violates the rights of any third party; and (d) backing up and
        maintaining Customer Data.
        <br />
        <br />
        6.5 GDPR Reference. If OpenTurf processes Customer's "Personal Data"
        under this Agreement, and such Personal Data is subject to the EU
        General Data Protection Regulation (GDPR), the Customer may require
        OpenTurf to enter into a Data Processing Agreement ("DPA"). Under such a
        DPA, OpenTurf shall be the data "Processor," and the Customer shall be
        the data "Controller." The processing of Personal Data under this
        Agreement is subject to OpenTurf's DPA. The Customer may request to
        enter into the OpenTurf DPA in reference to the Services by sending a
        request to{" "}
        <span
          onClick={handleContactClick}
          className="underline text-purple-800 cursor-pointer"
        >
          hello@fexo.io
        </span>
        . All capitalized terms not defined under this Section 6.5 shall have
        the same meaning as ascribed to them under Article 4 of GDPR.
        <br />
        <br />
        6.6 OpenTurf's Responsibility. OpenTurf will use commercially reasonable
        efforts to maintain the security and integrity of the Services and all
        Customer Data controlled by OpenTurf. OpenTurf is not responsible for
        unauthorized access to Customer Data or the unauthorized use of the
        Services unless such access is directly due to OpenTurf's gross
        negligence or willful misconduct. Customer is responsible for the use of
        the Services by any person to whom Customer has given access, even if
        Customer did not authorize such use. OpenTurf reserves the right to
        retain Customer Data for up to ninety (90) days following the
        termination or expiration of the corresponding Order. Thereafter,
        Customer agrees and acknowledges that Customer Data may be irretrievably
        deleted.
        <br />
      </p>
      <h2 className="static-subheading">7. FEES, ORDERS, AND TAXES</h2>
      <p className="text-stone-900 text-base font-normal ">
        7.1 Fees. Customer shall pay OpenTurf the fees as set forth in each
        applicable Order(s) (collectively, the "Fees"). Customer acknowledges
        that it has no right to return the Services, and all Fees are
        non-refundable. If OpenTurf is required to initiate legal action due to
        non-payment of fees, Customer shall bear all costs resulting from the
        collection of such fees.
        <br />
        <br />
        7.2 Additional Services. Customer may place Orders for additional
        Services or extend the term of existing Services by specifying order
        details in an Order form agreed to in writing by the parties,
        referencing the terms and conditions of this Agreement.
        <br />
        <br />
        7.3 Taxes. If OpenTurf has the legal obligation under applicable law to
        pay or collect taxes for which the Customer is responsible, the
        appropriate amount shall be invoiced to and paid by the Customer. This
        includes but is not limited to sales, use, transfer, privilege, excise,
        and all other taxes and duties imposed by reason of OpenTurf's
        performance.
        <br />
      </p>
      <h2 className="static-subheading">8. TERM AND TERMINATION</h2>
      <p className="text-stone-900 text-base font-normal ">
        8.1 Duration. This Agreement becomes effective on the "Effective Date"
        and remains in force until the conclusion of the term specified in the
        last Order (the "Term"), unless earlier terminated as outlined in
        Section 8. The Effective Date refers to when the Customer enters into
        this Agreement, signs an Order, or commences the use of our Services
        during the Trial. Each Order will automatically renew at the end of its
        term, unless either party provides written notice of non-renewal before
        the current term concludes.
        <br />
        <br />
        8.2 Termination for Breach. Either party may terminate this Agreement or
        specific Orders: (a) if the other party materially breaches the
        Agreement, with a 30-day notice for remediable breaches, or immediate
        notice for non-remediable breaches; or (b) if OpenTurf, with written
        notice, determines that Customer has made attempts to assign for the
        benefit of creditors, compositions with creditors, or has actions or
        proceedings under bankruptcy or insolvency laws within an unresolved
        60-day period.
        <br />
        <br />
        8.3 Consequences of Termination. Upon expiration or termination,
        Customer must (i) cease Service use and (ii) return all OpenTurf
        Confidential Information, provided software, and related materials.
        Termination does not exempt Customer from the obligation to pay all Fees
        accrued before termination. If OpenTurf terminates under Section 8.2
        (a), Customer must pay all Fees for the entire term specified in the
        corresponding Order(s).
        <br />
        <br />
        8.4 Survival. Provisions in Sections 5.1 (Ownership), 8.3 (Consequences
        of Termination), 8.4 (Survival), 9 (Confidentiality), 10.1 (Customer
        Indemnification), 12 (Limitation of Liability), and 13 (Miscellaneous)
        persist after Agreement termination.
        <br />
      </p>
      <h2 className="static-subheading">9. CONFIDENTIALITY</h2>
      <p className="text-stone-900 text-base font-normal ">
        During this Agreement's term, both parties may share confidential and
        proprietary materials and information ("Confidential Information"). Any
        information identified as "Confidential" or similar, and information the
        receiving party should reasonably know is the disclosing party's
        Confidential Information, is considered Confidential Information. The
        Agreement itself and all pricing terms fall under OpenTurf Confidential
        Information. The receiving party must maintain confidentiality, not
        disclose to third parties without written consent, and use the
        information internally for agreed-upon purposes. Exceptions include
        information publicly available without breach, independently developed,
        disclosed by a third party without restrictions, or lawfully possessed
        by the receiving party before disclosure. The receiving party may
        disclose Confidential Information under legal requirements, with prompt
        written notice to the disclosing party.
        <br />
      </p>
      <h2 className="static-subheading">10. INDEMNIFICATION</h2>
      <p className="text-stone-900 text-base font-normal ">
        10.1 Customer's Indemnification. Customer will defend, indemnify, and
        hold OpenTurf, its affiliates, suppliers, and licensors, along with
        their officers, directors, employees, and representatives, harmless from
        claims, damages, losses, liabilities, costs, and expenses (including
        reasonable attorneys' fees) related to: (a) Customer Data; (b) breach of
        this Agreement or violation of applicable law by Customer; or (c)
        alleged infringement or misappropriation of third-party intellectual
        property rights resulting from Customer Data or caused by the Customer.
        <br />
        <br />
        10.2 OpenTurf' Indemnification. OpenTurf will defend, indemnify, and
        hold Customer harmless from third-party claims, damages, losses,
        liabilities, costs, and expenses (including reasonable attorneys' fees)
        arising from claims that Customer's use of the Service directly
        infringes or misappropriates a third party's intellectual property
        rights ("Infringement Claim"). OpenTurf has no obligation for
        Infringement Claims arising from: (a) combining Customer Data with the
        Service; (b) combining third-party products or services (excluding
        OpenTurf-provided) with the Service; or (c) non-discretionary designs or
        specifications provided by Customer causing the Infringement Claim.
        Customer must reimburse OpenTurf for damages, losses, costs, and
        expenses due to the mentioned actions.
        <br />
        <br />
        10.3 Claim Notice and Indemnity Procedure. In seeking indemnity, the
        Indemnified Party must (a) notify the indemnifying party in writing
        within 30 days of a claim, providing necessary information for
        evaluation, and (b) allow the indemnifying party to control the defense,
        including choosing counsel. Cooperation is required. The indemnifying
        party has no obligation for losses, damages, or settlements incurred by
        the Indemnified Party without prior written consent. This Section
        constitutes the complete understanding of the parties regarding
        liability, including Infringement Claims, and indemnification.
        <br />
      </p>
      <h2 className="static-subheading">11. WARRANTY</h2>
      <p className="text-stone-900 text-base font-normal ">
        11.1 Service Warranty. The Services, used as specified in this Agreement
        and in compliance with applicable specifications, will perform the
        functions described in the corresponding Order ("Specification") in all
        material respects throughout the Order's term.
        <br />
        <br />
        11.2 Remedies. Customer must report any breach of the warranty in
        Section 11.1 to OpenTurf. In case of a breach by OpenTurf, Customer's
        exclusive remedy and OpenTurf's sole liability is the prompt correction
        of material non-conformance to minimize adverse effects on Customer's
        business.
        <br />
        <br />
        11.3 No Implied Warranties. OpenTurf does not guarantee uninterrupted or
        error-free Service operation, or compatibility with external hardware,
        software, systems, or data not provided by OpenTurf. Except for Section
        11.1, OpenTurf excludes all other express or implied warranties,
        including but not limited to merchantability, fitness for a particular
        purpose, satisfactory quality, or non-infringement.
        <br />
      </p>
      <h2 className="static-subheading">12. LIMITATIONS OF LIABILITY</h2>
      <p className="text-stone-900 text-base font-normal ">
        12.1 Excluding Special Damages. Except for Section 9
        ("Confidentiality"), neither party nor their affiliates, employees,
        directors, officers, licensors, service providers, or suppliers will be
        liable for any special, incidental, consequential, punitive, or indirect
        damages under any legal or equitable theory, including breach of
        contract, tort, strict liability, and others.
        <br />
        <br />
        12.2 Monetary Limitation. OpenTurf's total liability for all claims
        under this Agreement, whether in contract, tort, or otherwise, will not
        exceed the Fees paid or payable by Customer under the applicable Order
        during the preceding one (1) month.
        <br />
      </p>
      <h2 className="static-subheading">13. MISCELLANEOUS</h2>
      <p className="text-stone-900 text-base font-normal ">
        13.1 Export Control. Customer certifies compliance with current Indian
        Export Control laws and agrees to indemnify OpenTurf for any liability
        resulting from Customer's violation of Indian Export Control laws.
        <br />
        <br />
        13.2 Compliance with Laws. Customer must comply with all applicable laws
        and regulations when using any Service. Customer agrees to defend,
        indemnify, and hold OpenTurf harmless from claims, losses, liabilities,
        damages, judgments, and costs arising from any third-party claim of
        Customer's violation of privacy laws or regulations.
        <br />
        <br />
        13.3 Assignment. Neither party can transfer rights and obligations under
        this Agreement without the other party's written consent. However,
        OpenTurf may transfer its rights under this Agreement without consent in
        cases of a change in control, acquisition, or substantial asset sale.
        <br />
        <br />
        13.4 Force Majeure. Neither party is responsible for performance failure
        or delay due to events beyond reasonable control, constituting a Force
        Majeure. If a Force Majeure lasts over 30 days, either party may
        terminate the agreement with written notice.
        <br />
        <br />
        13.5 Independent Contractor. Both parties acknowledge that OpenTurf
        operates as an independent contractor. This Agreement does not create
        any agency, partnership, joint venture, or employment relationship. The
        Customer lacks the authority to bind OpenTurf in any manner.
        <br />
        <br />
        13.6 Governing Law. This Agreement is exclusively governed and construed
        according to the laws of the Republic of India, without considering
        conflict of law provisions. Disputes arising from or related to this
        Agreement, excluding those subject to Section 13.8 (Binding
        Arbitration), shall be exclusively adjudicated by the courts of the city
        of Bengaluru located in the state of Karnataka in India. Both parties
        consent to the jurisdiction of these courts and waive any right to
        challenge their appropriateness, whether based on the doctrine of forum
        non conveniens or otherwise. The United Nations Convention on Contracts
        for the International Sale of Goods does not apply to this Agreement or
        any Orders issued under it.
        <br />
        <br />
        13.7 Binding Arbitration. Any unresolved dispute or claim arising from
        this Agreement, except as otherwise provided or for intellectual
        property disputes seeking provisional or equitable remedies, will
        undergo mandatory and binding arbitration. The dispute shall be referred
        to and finally resolved by arbitration presided over by a mutually
        appointed sole arbitrator. The proceedings shall be conducted as per the
        Arbitration and Conciliation Act, 1996 and the rules framed thereunder
        with its seat and venue in Bengaluru, Karnataka, India. The language and
        award of the arbitration shall be English. The decision of the
        arbitrator shall be final and binding upon the Parties, and judgment may
        be entered in any court with jurisdiction. Limited discovery is allowed
        before the arbitration hearing, covering interrogatories, admissions,
        exchange of witness lists and documents, and depositions of all party
        witnesses. Depositions adhere to the Arbitration and Conciliation Act,
        1996, and the arbitrator must provide a written basis for the award or
        order. All hearings are recorded by a court reporter, constituting the
        official transcript.
        <br />
        <br />
        13.8 Marketing. Customer grants OpenTurf permission to identify them as
        a OpenTurf’s Customer, using their name, mark, and/or logo on OpenTurf's
        website and marketing materials related to the Customer's use of the
        Service.
        <br />
        <br />
        13.9 Entire Agreement. This Agreement constitutes the comprehensive and
        exclusive understanding between the parties, superseding all prior
        written or oral agreements, communications, and understandings related
        to its subject matter. Any waivers or modifications must be in writing
        and signed by both parties, unless otherwise provided herein. If any
        term or provision of this Agreement is illegal or unenforceable, it will
        be interpreted to the fullest extent possible to be valid, with the
        validity or enforceability of the remaining terms unaffected. In case of
        a conflict between this Agreement and the Order document, the terms of
        this Agreement shall prevail, except for expressly modified terms in any
        Order regarding that specific Order.
        <br />
        <br />
        13.10 Notice. Written notices between parties can be personally
        delivered, sent by registered courier services or certified mail (return
        receipt) to info@openturf.in.
        <br />
        <br />
        If you do not agree to the Terms, we request that you stop using the
        Services immediately. As long as you continue to use the Services, you
        are bound by the Terms mentioned here.
      </p>
      <br />
    </div>
  );
}

export default Terms;
