import React from "react";
import { CheckCircleOutline } from "@mui/icons-material";
//import { YOUTUBE_HOMEPAGE } from "../config";
import BookCallBtn from "../components/InvoiceProcessing/BookCallBtn";
import { FaLongArrowAltDown } from "react-icons/fa";
import ContentInPoints from "../components/HomePageContent/ContentInPoints";

const keybenefit_points = [
  [
    "Financial Documents",
    "Loan and Banking Information",
    "Business Documents",
    "Legal and Compliance Documents",
    "Registration Certificate",
  ],
  [
    "KYC Documents",
    "Credit Rating Reports",
    "Collateral Documents",
    "Promoter/Owner Details",
    "Other Supporting Documents",
  ],
];

function CreditRiskAssessment() {
  return (
    <div className="text-justify">
      <div className="text-stone-900 text-4xl font-bold text-center ">
        Credit Risk Assessment
      </div>

      <div className="mt-8">
        <div className="text-stone-900 text-lg font-normal middle-container">
          Fast-track your wholesale (SME/Corporate) lending decisions with
          Fexo's AI-driven Credit Risk Assessment platform. With India's
          first-of-it's-kind <b>CAM(Credit Assessment Module) Preparation</b>,
          your credit decisioning becomes 10x faster
        </div>
        <div className="flex justify-center mb-8 mt-8 w-full">
          <BookCallBtn className="w-64 h-16 z-50" text="Book a demo" />
        </div>
        <div className="bg-zinc-100 w-full">
          <div className="middle-container">
            <div className="font-bold flex justify-center items-c pt-8 mb-8 text-xl">
              Get a high-quality, comprehensive CAM report by Providing:{" "}
            </div>

            <div className=" pb-8">
              {/* {feature.keyFeatures.map((item, idx) => ( */}
              <div className="flex justify-center items-center w-full">
                <ContentInPoints rows={keybenefit_points} />
              </div>
              {/* ))} */}
            </div>
          </div>
        </div>

        {/* <div className="w-full rounded-lg bg-white shadow-xl p-5 mt-5 md:mt-10">
            <iframe
              frameBorder="0"
              allowFullScreen="1"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              title={`Demo Video`}
              src={`https://www.youtube.com/embed/${YOUTUBE_HOMEPAGE}?autoplay=0&amp;cc_lang_pref=en&amp;cc_load_policy=1&amp;controls=0&amp;rel=0&amp;hl=en&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fsupport.google.com&amp;widgetid=1`}
              className="w-full h-[300px] rounded-lg lg:h-[450px]"
            />
          </div> */}
      </div>
      <div className="w-full flex justify-center items-center">
        <div className="flex flex-col text-lg font-normal justify-center items-center mt-8 w-full rounded-lg">
          <b>How it works</b>
          <br />
          Add Documents
          <FaLongArrowAltDown className="text-xl" />
          View Cam Report
          <FaLongArrowAltDown className="text-xl" />
          Customised Export
        </div>
      </div>
      <div className="flex justify-center mt-10 w-full">
        <BookCallBtn className="w-64 h-16 z-50" text="Book a demo" />
      </div>
    </div>
  );
}

export default CreditRiskAssessment;
