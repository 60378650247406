import React from "react";
import { CheckCircleOutline } from "@mui/icons-material";
//import { YOUTUBE_HOMEPAGE } from "../config";
import BookCallBtn from "../components/InvoiceProcessing/BookCallBtn";
import { FaLongArrowAltDown } from "react-icons/fa";
import ContentInPoints from "../components/HomePageContent/ContentInPoints";

const keybenefit_points = [
  [
    "Supports Vertical and T-formats",
    "Custom classification",
    "Verifiable AI Approach",
  ],
  ["Real-time Insights", "Real-time Search and Conversation"],
];

function FinancialStatement() {
  return (
    <div className="text-justify">
      <div className="text-stone-900 text-4xl mb-8 font-bold text-center ">
        Financial Statement Analysis
      </div>
      <div>
        <div className="mb-8">
          <div className="text-stone-900 text-lg font-normal middle-container">
            Uncover actionable insights, both <b>quantitative</b> and{" "}
            <b>qualitative</b>, from multi-year financial statements in a
            unified view. Our evidence driven platform enables real-time
            prevention of adverse selections in credit decisions.
          </div>
        </div>
        <div className="flex justify-center mb-8 w-full">
          <BookCallBtn className="w-64 h-16 z-50" text="Book a demo" />
        </div>

        <div className="bg-zinc-100 w-full">
          <div className="pt-8 mb-8">
            <div className=" flex justify-center items-center font-semibold text-xl middle-container">
              Key Features
            </div>
          </div>
          <div className="mb-8">
            <div className=" pb-8">
              <div className="flex justify-center items-center w-full">
                <ContentInPoints rows={keybenefit_points} />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="w-full rounded-lg bg-white shadow-xl p-5 mt-5 md:mt-10">
            <iframe
              frameBorder="0"
              allowFullScreen="1"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              title={`Demo Video`}
              src={`https://www.youtube.com/embed/${YOUTUBE_HOMEPAGE}?autoplay=0&amp;cc_lang_pref=en&amp;cc_load_policy=1&amp;controls=0&amp;rel=0&amp;hl=en&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fsupport.google.com&amp;widgetid=1`}
              className="w-full h-[300px] rounded-lg lg:h-[450px]"
            />
          </div> */}
        <div className="w-full flex justify-center items-center">
          <div className="flex flex-col text-lg font-normal justify-center items-center mb-8">
            <div className=" flex justify-center items-center mb-8 font-semibold text-xl">
              How it Works
            </div>
            Add Documents
            <FaLongArrowAltDown className="text-xl" />
            View Insights
            <FaLongArrowAltDown className="text-xl" />
            Customised Export
          </div>
        </div>
        <div className="flex justify-center w-full">
          <BookCallBtn className="w-64 h-16 z-50" text="Book a demo" />
        </div>
      </div>
    </div>
  );
}

export default FinancialStatement;
