import React from 'react'


const SigninBtn = ({className}) => {
    const handleOnClick = async () => {
        window.open("https://books.fexo.io/", "_blank");
    };
    return (
        <button 
            className={`${className}  bg-orange-500 rounded-lg shadow-xl text-white text-lg`}
            onClick={() => {handleOnClick();}}
            
        >
            Sign-in
        </button>
    )
}

export default SigninBtn