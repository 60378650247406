import React from "react";
import { Link } from "react-router-dom";

function Privacy() {
  return (
    <div className="text-justify">
      <p className="text-stone-900 text-base font-normal">
        Effective Date: 10th January 2024
        <br />
      </p>
      <h2 className="static-subheading">1. Overview.</h2>
      <p className="text-stone-900 text-base font-normal text-justify">
        OpenTurf Technologies Private Limited ("OpenTurf" “Company” "we" "us"
        "our") is committed to protecting the privacy and security of your
        (“Customer” “User” “your” “you”) information. This Privacy Statement
        explains how we collect, use, disclose, and safeguard your data when you
        use the software platform by name Fexo (“Service”).
        <br />
        <br />
        We are dedicated to safeguarding your personal information and
        respecting your privacy rights. If you have any inquiries or concerns
        regarding this privacy notice or how we handle your personal
        information, please reach out to us at info@openturf.in.
        <br />
        <br />
        When you explore our website at{" "}
        <Link
          className="underline text-purple-800"
          target="_blank"
          rel="noopener noreferrer"
          to="https://www.fexo.io"
        >
          www.fexo.io
        </Link>{" "}
        or more broadly any subdomains, such as{" "}
        <Link
          className="underline text-purple-800"
          target="_blank"
          rel="noopener noreferrer"
          to="https://books.fexo.io"
        >
          books.fexo.io
        </Link>{" "}
        and utilize any of our Services, encompassing the Website, you entrust
        us with your personal information. We treat your privacy with the utmost
        seriousness. In this privacy notice, we aim to clarify what information
        we gather, how we use it, and the rights you possess concerning it. We
        encourage you to read it thoroughly, as it holds significance.
        <br />
        <br />
        This privacy policy covers all information collected through our
        Services, as outlined above (encompassing our Website), as well as any
        associated services, sales, marketing, or events. Kindly review this
        privacy notice carefully, as it provides insights into how we handle the
        information we gather.
        <br />
        <br />
        By using the Services, including from the Website, you agree to the
        terms outlined in this Privacy Statement.
        <br />
      </p>
      <h2 className="static-subheading">2. Information we collect.</h2>
      <p className="text-stone-900 text-base font-normal">
        2.1 Personal Information. We gather personal information that you
        disclose to us voluntarily.
        <br />
        <br />
        This occurs when you register on the Website, express interest in
        learning more about us or our products and services, engage in Website
        activities, or contact us directly. The personal information collected
        depends on your interactions, choices, and use of products and features.
        Examples of such information include names, phone numbers, email
        addresses, mailing addresses, job titles, usernames, passwords, contact
        preferences, authentication data, and similar details. Additionally, we
        may collect data necessary to process your payment if you make
        purchases, such as your payment instrument details (like credit or debit
        card number), and the security code associated with your payment
        instrument. All payment data is stored by our payment processors. You
        may review their privacy policies at their website.
        <br />
        <br />
        All personal information that you provide to us must be true and
        accurate, and you must notify us of any changes to such personal
        information.
        <br />
        <br />
        2.2 Information collected by the Website.
        <br />
        <br />
        Certain information, such as your Internet Protocol (IP) address,
        browser, and device characteristics, is automatically gathered when you
        visit our Website. This data, collectively known as device and usage
        information, does not disclose your specific identity, including
        personal details like your name or contact information. It encompasses
        details like IP address, browser and device specifications, operating
        system, language preferences, referring URLs, device name, country,
        location, and technical information related to your interaction with our
        Website.
        <br />
        <br />
        This information is crucial for maintaining the security and
        functionality of our Website, as well as for internal analytics and
        reporting purposes. Similar to many businesses, we employ cookies and
        comparable technologies to collect this data.
        <br />
        <br />
        The collected information also includes log and usage data, which
        encompasses service-related, diagnostic, usage, and performance details
        automatically recorded by our servers. Depending on your interactions,
        this may involve IP address, device information, browser type and
        settings, and specifics about your Website activity. Device data, such
        as information about the device you use, is also collected, covering
        details like IP address, device identification numbers, location,
        browser type, hardware model, Internet service provider, and operating
        system information.
        <br />
        <br />
        Furthermore, location data is gathered to understand your device's
        location, either precisely or imprecisely, depending on your device type
        and settings. Techniques like GPS may be used for geolocation data based
        on your IP address. You have the option to decline this information
        collection by denying access or disabling the Location setting on your
        device. It's important to note that opting out may limit your ability to
        use certain aspects of the Services.
        <br />
        <br />
        2.3 Information collected from external sources.
        <br />
        <br />
        To improve our ability to deliver relevant marketing, offers, and
        services tailored to you, as well as to maintain accurate records, we
        may gather information from various sources, including public databases,
        joint marketing partners, affiliate programs, data providers, and social
        media platforms. This information may encompass mailing addresses, job
        titles, email addresses, phone numbers, intent data (or user behavior
        data), Internet Protocol (IP) addresses, social media profiles, social
        media URLs, and custom profiles, all utilized for targeted advertising
        and event promotion purposes. Should you engage with us via a social
        media platform using your account credentials (e.g., Facebook or
        Twitter), we may receive personal details such as your name, email
        address, and gender. However, the collection of any personal information
        from your social media account is contingent upon your privacy settings
        on said platform.
      </p>
      <h2 className="static-subheading">
        3. How we utilize the collected information.
      </h2>
      <p className="text-stone-900 text-base font-normal">
        We process your information for various purposes grounded in legitimate
        business interests, fulfilling contractual obligations, complying with
        legal requirements, and/or obtaining your consent.
        <br />
        <br />
        The personal information collected through our Website serves several
        business objectives, and we process it based on our legitimate business
        interests, contractual necessity, consent, and legal obligations. The
        specific grounds for processing are indicated next to each purpose
        outlined below:
      </p>
      <br />
      <br />
      <ul>
        <li>
          Facilitate Account Creation and Logon Process: If you opt to link your
          account with us to a third-party account (such as Google or Facebook),
          we use information obtained from those third parties to streamline the
          account creation and logon process as part of our contractual
          performance.
        </li>
        <br />
        <li>
          Post Testimonials: Testimonials containing personal information are
          posted on our Website. Before posting, we secure your consent to use
          your name and testimonial content. To update or delete your
          testimonial, please contact us at info@openturf.in, providing your
          name, testimonial location, and contact information.
        </li>
        <br />
        <li>
          Request Feedback: Your information may be used to request feedback and
          contact you regarding your Website usage.
        </li>
        <br />
        <li>
          Enable User-to-User Communications: Information may be utilized to
          facilitate user-to-user communications with individual consent.
        </li>
        <br />
        <li>
          Manage User Accounts: Information is employed for managing our
          accounts and ensuring their proper functionality.
        </li>
        <br />
        <li>
          Send Administrative Information: Personal information may be used to
          send product, service, and new feature information, as well as updates
          to our terms, conditions, and policies.
        </li>
        <br />
        <li>
          Protect Our Services: Information is utilized in efforts to maintain
          the safety and security of our Website, including fraud monitoring and
          prevention.
        </li>
        <br />
        <li>
          Enforce Terms, Conditions, and Policies: Information may be processed
          for business purposes, legal and regulatory compliance, or in
          connection with our contractual obligations.
        </li>
        <br />
        <li>
          Respond to Legal Requests and Prevent Harm: In case of legal /
          regulatory request, we may inspect held data to determine an
          appropriate response.
        </li>
        <br />
        <li>
          Fulfill and Manage Orders: Information is employed to fulfill and
          manage orders, payments, returns, and exchanges made through the
          Website.
        </li>
        <br />
        <li>
          Deliver and Facilitate Service Delivery: Information is used to
          provide the requested service to users.
        </li>
        <br />
        <li>
          Respond to User Inquiries/Offer Support: Information may be used to
          address user inquiries and provide support for any issues related to
          our Services.
        </li>
        <br />
        <li>
          Send Marketing and Promotional Communications: Personal information
          may be used for marketing purposes in accordance with your
          preferences, and you can opt-out at any time.
        </li>
        <br />
        <li>
          Administer loyalty benefits: Information is utilized to administer
          offers from competitions, prize draws and other loyalty benefits for
          participants.
        </li>
        <br />
        <li>
          Deliver Targeted Advertising: Information is utilized to create and
          display personalized content and advertising tailored to user
          interests and/or location, measuring its effectiveness. We may
          collaborate with third parties for these purposes.
        </li>
        <br />
      </ul>
      <h2 className="static-subheading">
        4. Will the collected information be shared with anyone?
      </h2>
      <p className="text-stone-900 text-base font-normal">
        We only share your information under specific circumstances, including
        with your consent, to comply with laws, to provide services, to protect
        your rights, or to fulfill business obligations. The legal bases for
        processing or sharing your data include:
        <br />
        <br />
      </p>
      <ul>
        <li>
          Consent: We may process your data if you have provided explicit
          consent for a specific purpose.
        </li>
        <br />
        <li>
          Legitimate Interests: Data may be processed when necessary to achieve
          our legitimate business interests.
        </li>
        <br />
        <li>
          Performance of a Contract: If a contract is in place, we process
          personal information to fulfill its terms.
        </li>
        <br />
        <li>
          Legal Obligations: Information may be disclosed to comply with
          applicable laws, governmental requests, court orders, or legal
          processes, such as responding to a subpoena.
        </li>
        <br />
        <li>
          Vital Interests: Data may be disclosed when necessary to investigate,
          prevent, or take action regarding potential policy violations, fraud,
          threats to safety, illegal activities, or as evidence in litigation.
        </li>
        <br />
      </ul>
      <p className="text-stone-900 text-base font-normal">
        Situations where data processing or sharing may occur include, but not
        limited, to the below:
      </p>
      <br />
      <ul>
        <li>
          Business Transfers: Information may be shared during negotiations or
          in connection with a merger, sale of assets, financing, or acquisition
          of our business.
        </li>
        <li>
          Vendors, Consultants, and Third-Party Service Providers: Data may be
          shared with entities providing services on our behalf, such as payment
          processing, data analysis, and marketing. Selected third parties may
          use tracking technology on the Website for analytics and understanding
          online activity.
        </li>
        <li>
          Affiliates: Information may be shared with our affiliates, including
          our parent company, subsidiaries, or companies under common control.
        </li>
        <li>
          Business Partners: Data may be shared with business partners for
          offering specific products, services, or promotions.
        </li>
      </ul>
      <p className="text-stone-900 text-base font-normal">
        We only share information with specific third parties, categorized based
        on the purpose of data collection and processing. If your data was
        processed based on consent, and you want to revoke it, contact us on
        info@openturf.in.
      </p>
      <br />
      <h2 className="static-subheading">
        5. Do we use cookies and other tracking technologies?
      </h2>
      <p className="text-stone-900 text-base font-normal">
        We may employ cookies and similar tracking technologies to collect and
        store information. Details on their usage and how to refuse certain
        cookies are outlined in our Cookie Notice. We use cookies and similar
        technologies to enhance your experience, analyze usage patterns, and
        personalize content.
      </p>
      <br />
      <h2 className="static-subheading">
        6. How do we protect your information with us?
      </h2>
      <p className="text-stone-900 text-base font-normal">
        We aim to protect your personal information through a system of
        organizational and technical security measures. We utilize
        industry-standard security measures to safeguard your data from
        unauthorized access, disclosure, alteration, and destruction. These
        measures include but are not limited to encryption protocols, firewalls,
        secure server infrastructure, access controls, regular security audits
        and access controls. By implementing these robust security practices, we
        strive to maintain the confidentiality, integrity, and availability of
        your data. <br />
        <br />
        While we implement safeguards and make every effort to secure your
        information, it's important to note that no electronic transmission over
        the Internet or storage technology can be guaranteed to be completely
        secure. As such, we cannot ensure or warrant that hackers,
        cybercriminals, or other unauthorized third parties will not circumvent
        our security measures to improperly collect, access, steal, or modify
        your information. While we are committed to protecting your personal
        information to the best of our ability. As such, we advise accessing the
        Website only within a secure environment.
        <br />
      </p>
      <h2 className="static-subheading">
        7. Is your information transferred outside your country?
      </h2>
      <p className="text-stone-900 text-base font-normal">
        We may transfer, store, and process your information in countries other
        than your own. Our servers are located in India, the United States, the
        EU and the Middle East. Please be aware that your information may be
        transferred to, stored, and processed by us in our facilities and by
        those third parties with whom we may share your personal information, in
        and other countries. For further clarifications, if any, reach out to us
        on the contact details mentioned in this page.
      </p>
      <br />
      <h2 className="static-subheading">
        8. How long do we keep the information?
      </h2>
      <p className="text-stone-900 text-base font-normal">
        We keep your information for as long as necessary to fulfill the
        purposes outlined in this privacy notice unless otherwise required by
        law. We will retain your personal information only for as long as it is
        necessary to fulfill the purposes outlined in this privacy notice,
        unless a longer retention period is mandated or allowed by law (such as
        for tax, accounting, or other legal obligations). Once we no longer have
        a legitimate business need to process your personal information, we will
        either delete or anonymize it. If deletion or anonymization is not
        feasible (for instance, due to storage in backup archives), we will
        securely store your personal information and restrict it from further
        processing until deletion becomes feasible.
      </p>
      <br />
      <h2 className="static-subheading">9. Your privacy choices.</h2>
      <p className="text-stone-900 text-base font-normal">
        We understand the importance of providing you with control over your
        personal information. Therefore, you have the following options to
        manage your account information and communication preferences:
      </p>
      <br />
      <ul>
        <li>
          Update Your Account Information: You have the ability to review and
          update your account details, such as your name, email address, and
          contact information, by accessing your profile/account settings.
        </li>
        <br />
        <li>
          Manage Communication Preferences: You can choose the types of
          communications you receive from us and how you prefer to receive them.
          This includes opting in or out of promotional emails, newsletters, and
          other marketing communications.
        </li>
        <br />
        <li>
          Opt-Out of Promotional Communications: If you no longer wish to
          receive promotional communications from us, you have the option to
          unsubscribe by following the instructions provided in our emails or by
          contacting us directly.
        </li>
        <br />
      </ul>
      <p className="text-stone-900 text-base font-normal">
        We respect your choices and will honor the requests you make regarding
        the use and disclosure of your personal information for marketing
        purposes.
        <br />
        <br />
        You can contact us on the contact details provided in this page.
      </p>
      <br />
      <h2 className="static-subheading">
        10. Updates to this Privacy Statement.
      </h2>
      <p className="text-stone-900 text-base font-normal">
        We update this Privacy Statement periodically. We encourage you to
        review this privacy notice frequently to be informed of how we are
        protecting your information. We will notify you of any material changes
        by posting the updated statement on our Website; send you a notification
        on the platform; send you a direct notification.
      </p>
      <br />
      <h2 className="static-subheading">11. Notice.</h2>
      <p className="text-stone-900 text-base font-normal">
        Written notices between parties can be personally delivered, sent by
        registered courier services or certified mail (return receipt) to
        info@openturf.in.
        <br />
        <br />
        If you do not agree to our privacy policy, we request that you stop
        using the Services immediately. As long as you continue to use the
        Services, you are bound by the Privacy Statements mentioned here.
        <br />
        <br />
      </p>
    </div>
  );
}

export default Privacy;
