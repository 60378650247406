import React from "react";
import BookCallBtn from "../InvoiceProcessing/BookCallBtn";
import {
  CreditRiskIcon,
  BookKeepingIcon,
  KycDocumentIcon,
  TradeFinanceIcon,
  ComplianceIcon,
  CustomerSupportIcon,
} from "../../images";

const Offerings = () => {
  const offerings = [
    {
      title: "Credit Risk Assessment",
      description:
        "Transform raw financial and bank statements into meaningful insights",
      link: "/creaditassessment",
      icon: <CreditRiskIcon className="w-8 h-8" />,
    },
    {
      title: "Financial Statement Analysis",
      description:
        "Fexo simplifies financial data, providing clear insights and trends for smarter decision Making",
      link: "/financialstatement",
      icon: <ComplianceIcon className="w-8 h-8" />,
    },
    {
      title: "Trade Finance",
      description:
        "Analyze invoices and other trade documents to aid financial decision-making.",
      link: "/tradefinance",
      icon: <TradeFinanceIcon className="w-8 h-8" />,
    },
    {
      title: "KYC Management",
      description:
        "Simplify KYC compliance with our automated document extraction and validation system.",
      link: "/kycmanagement",
      icon: <KycDocumentIcon className="w-8 h-8" />,
    },
    // {
    //   title: "Regulatory Compliance",
    //   description:
    //     "Stay compliant with regulatory requirements using FEXO’s intelligent compliance bot.",
    //   link: "/regulatorycomplaince",
    //   icon: <ComplianceIcon className="w-8 h-8" />,
    // },
    {
      title: "Bookkeeping Automation",
      description: "Automate and streamline bookkeeping processes with FEXO.",
      link: "/bookkeepingautomation",
      icon: <BookKeepingIcon className="w-8 h-8" />,
    },
    {
      title: "Customer Support",
      description:
        "Analyze mutual fund and insurance documents to enhance customer support efficiency.",
      link: "/customersupport",
      icon: <CustomerSupportIcon className="w-8 h-8" />,
    },
  ];

  return (
    <div className="container mx-auto ">
      <div className="text-center text-4xl font-bold mb-5 py-5 text-[#2E0040]">
        Our Offerings
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {offerings.map((offering, index) => (
          <div key={index} className="p-6 border rounded-lg shadow-lg bg-white">
            <div className="text-xl mb-4 w-7 h-7">{offering.icon}</div>{" "}
            <div className="text-xl font-bold mb-2 text-[#864da2]">
              {offering.title}
            </div>
            <div className="text-[#1C1917] mb-4 text-base">
              {offering.description}
            </div>
            <a
              href={offering.link}
              className="text-indigo-700 font-semibold hover:underline"
            >
              Explore more
            </a>
          </div>
        ))}
      </div>
      <div className="flex w-full justify-center mt-10 pb-10">
        <BookCallBtn className=" w-64 h-16 z-50" text="Book a demo" />
      </div>
    </div>
  );
};

export default Offerings;
